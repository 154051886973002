.service-modal {
  .modal__body {
    max-width: 832px;
  }

  &__title {
    margin-bottom: 40px;
  }

  &__form {
    .form__textarea {
      display: block;
      margin-bottom: 24px;
      width: 100%;
      height: 180px;
    }
  }

  &__service {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 24px;
    color: #000;

    span {
      flex-shrink: 0;
    }

    @include small-tablet {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__value,
  &__text {
    flex-grow: 1;
    font-weight: 400;
    font-size: 24px;
    color: #000;
    width: 100%;
  }

}