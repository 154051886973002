.benefits {
  padding: 56px 0;
  background-color: #fbfbfb;
  margin-bottom: 168px;

  @include big-desktop {
    margin-bottom: 120px;
    padding: 40px 0;
  }

  @include small-tablet {
    margin-bottom: 0;
  }

  &__title {
    margin-bottom: 66px;

    @include big-desktop {
      margin-bottom: 40px;
    }
  }

  &__items {
    @include small-tablet {
      display: grid;
      grid-template-columns: 1fr;
      gap: 24px;
    }
  }

  &__slider {
    margin-bottom: 22px;

    .swiper-slide {
      width: auto;
      height: auto;
      box-sizing: border-box;

      @include small-tablet {
        width: 100%;
      }
    }
  }

  &__control {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include small-tablet {
      display: none;
    }
  }

  &__arr {
    cursor: pointer;

    svg {
      fill: #2A42CD;
    }

    &.swiper-button-disabled {
      svg {
        fill: #B0B0B0;
      }
    }
  }
}

.ben-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 382px;
  padding: 32px 27px;
  background-color: #fff;
  border-radius: 20px;
  height: 100%;

  @include big-desktop {
    max-width: 320px;
    padding: 30px;
  }

  @include small-tablet {
    max-width: 100%;
    padding: 32px;
  }

  &__image {
    display: flex;
    justify-content: center;
    height: 192px;
    margin-bottom: 24px;

    @include big-desktop {
      height: 170px;
    }

    @include small-tablet {
      height: 192px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    margin-bottom: 16px;
  }

  &__desc {

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}