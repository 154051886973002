.lk-support {
  display: none;

  &.active {
    display: block;
  }

  &__title {
    margin-bottom: 24px;
  }

  &__desc {
    max-width: 441px;
    margin-bottom: 40px;
  }

  &__form {
    @include small-tablet {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .form__label {
      display: block;
      width: 100%;
      max-width: 360px;

      &--big {
        height: 260px;
      }

      &:not(:last-of-type) {
        margin-bottom: 24px;
      }
    }

    .form__btn {
      margin-top: 40px;

      @include small-tablet {
        align-self: center;
      }
    }

    .form__textarea {
      display: block;
      width: 100%;
      height: 100%;

    }
  }
}