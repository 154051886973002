.work {
  margin-bottom: 168px;

  @include big-desktop {
    margin-bottom: 120px;
  }

  @include small-tablet {
    margin-bottom: 100px;
  }

  &__title {
    margin-bottom: 56px;

    @include big-desktop {
      margin-bottom: 40px;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;

    @include big-desktop {
      grid-template-rows: unset;
      grid-auto-rows: unset;
    }

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    border-radius: 20px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}