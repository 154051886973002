.about-section {
  margin-bottom: 168px;

  @include big-desktop {
    margin-bottom: 120px;
  }

  @include small-tablet {
    padding-top: 96px;
  }

  &__title {
    margin-bottom: 64px;

    @include big-desktop {
      margin-bottom: 40px;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 328px;
    gap: 24px;

    @media (max-width: 1600px) {
      grid-template-rows: unset;
    }

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__image {
    grid-column: 2 span;
    border-radius: 20px;
    overflow: hidden;

    @include tablet {
      order: -1;
      height: 300px;
    }

    @include small-tablet {
      height: 150px;
    }

    @include mobile {
      height: 86px;
      border-radius: 40px 0 0 40px;
    }

    img {
      height: 100%;
      width: 100%;

    }
  }

  &__item {
    @include small-tablet {
      grid-column: 2 span;
    }
  }
}

.about-item {
  padding: 32px;
  border-radius: 20px;
  background-color: #fcfbff;

  @include big-desktop {
    padding: 20px;
  }

  &__icon {
    width: 80px;
    height: 80px;
    margin-bottom: 24px;

    @include big-desktop {
      width: 60px;
      height: 60px;
    }

    @include tablet {
      width: 72px;
      height: 72px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    margin: 0;
    margin-bottom: 16px;
    line-height: 120%;
    color: #000;

    @include small-tablet {
      margin-bottom: 8px;
    }
  }

  &__desc {
    @include tablet {
      line-height: normal;
    }
  }
}