.correct {
  &__title {
    margin-bottom: 24px;
  }

  .modal__body {
    max-width: 878px;
    overflow-y: auto;

    @include small-tablet {
      width: 100%;
      height: 100%;
      max-width: unset;
    }
  }

  &__textarea {
    height: 190px;
    width: 100%;
    resize: none;
    margin-bottom: 32px;
  }

  &__list {
    position: relative;
    flex: 1;
    width: 100%;

    .text {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      text-align: center;
      transform: translate(-50%, -50%);
      font-weight: 600;
      font-size: 18px;
      color: #b5b5b5;
      display: none;

      &.active {
        display: block;
      }
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__tasks {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:not(:last-of-type) {
      padding-bottom: 24px;
      border-bottom: 1px solid #e5e5e5;
    }

    &:last-of-type {
      min-height: 250px;
    }

    @include big-desktop {
      margin-bottom: 25px;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 8px;

    .status {
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      font-weight: 500;

      &--pending {
        background-color: #ffc329;
      }

      &--notready {
        background-color: #ce2028;
      }

      &--ready {
        background-color: #23cc20;
      }
    }
  }

  &__task {
    font-weight: 600;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .form__btn {

      @include small-tablet {
        align-self: center
      }
    }
  }
}