.lk-services {
  display: none;

  &.active {
    display: block;
  }

  &__slider {
    min-width: 0;
    max-width: 100%;

    .swiper-slide {
      max-width: 100%;
      box-sizing: border-box;
      height: auto !important;
      display: flex;
      flex-direction: column;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    @media (max-width: 1600px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include small-tablet {
      grid-template-columns: 1fr;
    }
  }
}

.lk-service {
  padding: 32px 32px 48px;
  padding-right: 74px;
  background-color: #fff;
  min-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  &__title {
    margin-bottom: 24px;
  }



  &__desc {
    margin: 0;
    margin-bottom: 24px;
  }

  &__btn {
    margin-top: auto;
  }
}