.nav {
  @include small-tablet {
    display: none;
  }

  &__list {
    display: flex;
    align-items: center;
    gap: 80px;

    @include big-desktop {
      gap: 45px;
    }

    @include tablet {
      gap: 30px;
    }

    &--footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 32px 112px;
    }
  }



  &__link {
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    color: #000;
    position: relative;
    transition: color .3s ease-out;

    @include tablet {
      font-size: 14px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 10%;
      opacity: 0;
      height: 1px;
      background-color: var(--accent);
      will-change: width;
      transition: width .3s ease-out, opacity .3s ease-out;
    }

    @include hover {
      color: var(--accent);

      &::before {
        width: 100%;
        opacity: 1;
      }
    }
  }
}