.lk-header {
  width: 100%;
  padding-top: 32px;
  padding-bottom: 48px;

  @include tablet {
    padding: 20px 0;
  }

  @include small-tablet {
    padding: 16px 0;
  }

  &__admin {
    display: none;

    @include tablet {
      display: block;
    }
  }

  &__logo {
    @media (max-width: 375px) {
      width: 90px;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__btn {
    margin-left: 32px;
    margin-right: 24px;

    @include tablet {
      display: none;
    }

  }

  &__account {
    display: flex;
    align-items: center;
    gap: 8px;

    @include tablet {
      display: none;
    }

    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #000;

    span {
      font-weight: 500;
    }

    &--sidebar {
      display: none;
      margin-bottom: 16px;

      @include tablet {
        display: flex;
      }
    }

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &__logout {
    padding: 12px 24px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}