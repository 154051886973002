.hero {
  &__wrapper {
    position: relative;
  }

  margin-bottom: 100px;


  &__container {
    min-height: 980px;
    background-image: url('./../img/hero.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    border-radius: 20px 20px 20px 0;

    @include big-desktop {
      min-height: 100vh;
      padding-bottom: 0px;
    }

    @include tablet {
      min-height: 500px;
    }

    @include small-tablet {
      padding-bottom: 0;
      background-position: right;
    }

    @include mobile {
      // background-size: 100% 100%;
      min-height: 414px;
    }
  }


  &__title {
    margin: 0;
    padding-bottom: 16px;
    padding-right: 100px;
    font-weight: 500;
    font-size: 64px;
    color: #000;
    max-width: calc(41% + 100px);
    position: relative;

    span {
      position: relative;
      z-index: 3;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100px;
      width: 100%;
      background-color: #fff;
      border-top-right-radius: 20px;

      @include small-tablet {
        height: 60px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 120%;
      width: calc(100% - 90px);
      background-color: #fff;
      border-top-right-radius: 20px;
    }

    @include big-desktop {
      font-size: 48px;
      max-width: 50%;
    }

    @media (max-width: 850px) {
      max-width: 60%;
    }

    @include tablet {
      font-size: 38px;
      padding-bottom: 0;
    }


    @include small-tablet {
      max-width: 80%;
      font-size: 32px;
    }

    @include mobile {
      font-size: 24px;
      max-width: 80%;
    }
  }

  &__info {

    max-width: 60%;
    padding: 30px 30px 74px 0;

    position: relative;

    &-wrapper {
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &::before {
      content: "";
      position: absolute;

      left: 0;
      bottom: 0;
      width: 100%;
      height: 200px;
      background-color: #fff;
      border-radius: 0 20px 0 0;

      @include tablet {
        height: 100%;
      }
    }

    @include tablet {
      max-width: 600px;
      max-width: 80%;
      padding: 20px;
      padding-left: 0;
    }

    @media (max-width: 850px) {
      max-width: 82%;
    }

    @include small-tablet {
      max-width: 90%;
      padding-bottom: 0;
      padding-top: 16px;
    }
  }

  &__btn {
    &--mobile {
      display: none;

      @include mobile {
        display: inline-flex;
        margin: 32px 0;
      }
    }

    &--disable {
      @include mobile {
        display: none;
      }
    }
  }

  &__desc {
    max-width: 60%;
    margin: 0;

    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000;

    @include mobile {
      max-width: unset;
    }
  }

  &__offer {
    position: absolute;
    width: 29%;
    bottom: 37px;
    right: 60px;

    @media (max-width: 1600px) {
      width: 31%;
    }

    @include tablet {
      position: relative;
      right: unset;
      bottom: unset;
      width: 100%;
    }
  }

  &__slider {
    .swiper-slide {
      height: auto;
      box-sizing: border-box;
    }
  }

  &__pagination {
    .swiper-pagination-bullet {
      width: 16px;
      height: 16px;
      --swiper-pagination-bullet-horizontal-gap: 8px;

      &-active {
        background-color: var(--accent);
      }
    }
  }
}

.hero-slide {

  height: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 32px;

  @include tablet {
    background-color: #fcfbff;
  }

  &__title {
    margin: 0;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 24px;
    color: #000;

    @include small-tablet {
      font-size: 18px;
      margin-bottom: 16px;
    }
  }

  &__desc {
    margin: 0;

    font-weight: 400;
    font-size: 16px;
    color: #000;
  }
}