.form {
  &__label {
    height: 56px;
    // width: 360px;
    display: inline-block;
    position: relative;

    input {
      width: 100%;
      height: 100%;
      border: 1px solid #3d55dd;
      border-radius: 20px;
      padding: 16px 48px 16px 16px;

      &:focus {
        outline: none;
      }

      &.focus-visible {
        outline: 2px solid #3d55dd;
        outline-offset: -1px;
      }
    }

  }

  &__btn {
    .btn {
      svg {
        width: 13px;
        height: 11px;
      }
    }
  }

  &__flag {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);

    &+.form__input {
      padding-left: 40px;
    }
  }

  &__textarea {
    border: 1px solid #3d55dd;
    border-radius: 20px;
    padding: 16px 48px 16px 16px;
    resize: none;

    &:focus {
      outline: none;
    }

    &.focus-visible {
      outline: 2px solid #3d55dd;
      outline-offset: -1px;
    }
  }

  &__checkbox {
    width: 0;
    height: 0;
    position: absolute;
    left: -1000px;

    &:checked {
      &+.form__fake-label {
        .form__fake-checkbox {
          svg {
            opacity: 1;
          }
        }
      }
    }
  }

  &__agreement {
    display: flex;
    align-items: center;
    gap: 10px;
    grid-column: 2 span;

    @include mobile {
      grid-column: 1 span;
    }
  }

  &__fake-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: #fff;
    flex-shrink: 0;
    border-radius: 5px;

    svg {
      opacity: 0;
    }

  }

  &__fake-label {
    display: flex;
    align-items: center;
    gap: 16px;
    font-family: var(--third-family);
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #000;
    cursor: pointer;
  }
}