.lk-body {
  padding: 32px 40px;
  background-color: #fdfdfd;
  height: 100%;

  @include tablet {
    padding: 32px 0;
  }

  &__settings {
    display: none; // remove

    &.active {
      display: block;
    }

    .lk-settings {

      &__title {
        margin-bottom: 40px;
      }


    }
  }
}