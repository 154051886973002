.burger-menu {
  position: fixed;
  inset: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  transform: translateX(-100%);
  transition: transform .3s ease-out;

  @media (min-width: 769px) {
    display: none;
  }

  &.active {
    transform: translateX(0);
  }

  &__container {
    background-image: url('./../img/burger-menu.png');
    background-position: bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 48px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;

    @media (min-width: 600px) {
      background-image: url('./../img/burger-menu-2x.png');
    }
  }

  &__close {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__nav {
    display: block !important;
    margin-bottom: 40px;

    .nav__list {
      flex-direction: column;
      gap: 56px;
    }

    .nav__link {
      font-size: 24px;
    }
  }
}