// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Raleway", sans-serif;
  --second-family: "Montserrat", sans-serif;
  --third-family: "Century Gothic", sans-serif;
  --font3: "Nunito Sans", sans-serif;
  --font4: "Inter", sans-serif;
  --content-width: 1600px;
  --container-offset: 15px;

  @media (max-width: 1600px) {
    --content-width: 1400px;
  }

  @media (max-width: 1440px) {
    --content-width: 1200px;

  }

  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #fff;
  --accent: #3d55dd;
}