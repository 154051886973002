.modal {
  background-color: rgba(#000, 0.5);
  backdrop-filter: blur(4px);
  position: fixed;

  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: -1;

  transition: opacity .3s ease-out, z-index .3s ease-out;

  &.active {
    z-index: 102;
    opacity: 1;
  }

  &__body {
    background-color: #fff;
    width: 100%;
    max-width: 520px;
    padding: 40px 48px;
    position: relative;
    max-height: 95vh;
    overflow-y: auto;

    @include big-desktop {
      padding: 20px 30px;
    }

    @include small-tablet {
      width: 100%;
      height: 100%;
      max-width: unset;
      max-height: unset;
      padding: 56px calc(24px + var(--container-offset));
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-bottom: 40px;

    @include small-tablet {
      gap: 24px;
    }
  }

  &__btn {
    font-weight: 400;
    font-size: 32px;
    color: #000;
    position: relative;

    @include small-tablet {
      font-size: 24px;
    }

    @include mobile {
      font-size: 18px;
    }

    &:not(.active) {
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 10%;
        opacity: 0;
        height: 2px;
        background-color: #3d55dd;
        transition: width .3s ease-out, opacity .3s ease-out;
      }
    }

    &.active {
      color: #3d55dd;
      border-bottom: 2px solid #3d55dd;
    }

    &:hover {
      &::after {
        width: 100%;
        opacity: 1;
      }
    }
  }

  &__form {

    flex-direction: column;
    align-items: center;
    display: none;

    &.active {
      display: flex;
    }

    .form__label {
      width: 360px;

      &:not(:last-of-type) {
        margin-bottom: 40px;
      }

      &:last-of-type {
        margin-bottom: 32px;
      }
    }

    .form__btn {
      padding: 18px 53px;
      justify-content: center;
    }
  }
}